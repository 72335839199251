module.exports = [{
      plugin: require('/codebuild/output/src939585386/src/4m/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Playball","cursive"]}},
    },{
      plugin: require('/codebuild/output/src939585386/src/4m/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false},
    },{
      plugin: require('/codebuild/output/src939585386/src/4m/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
